import React, { useEffect } from 'react';  // Asegúrate de importar useEffect
import { FaHeartbeat, FaRunning, FaBed, FaCommentDots, FaMedapps } from 'react-icons/fa';
import Footer from '../Extras/Footer';
import Header from '../Extras/HeaderInicio';

const ManageStress = () => {
    useEffect(() => {
        // Forzar el desplazamiento al inicio cuando se carga el componente
        window.scrollTo(0, 0);
      }, []);
      return (
        <div className="manage-stress-section bg-gradient-to-r from-blue-50 to-indigo-100 min-h-screen flex flex-col justify-between">
          {/* Ajusta el padding para reducir la separación con el Header */}
          <Header />
          
          {/* Contenedor principal */}
          <div className="py-6 px-6 flex-grow"> 
            <h2 className="text-4xl font-bold text-center text-gray-900 mb-8">
          ¿Cómo Gestionar mi Estrés?
        </h2>
        <div className="content space-y-8">
          <p className="text-xl text-gray-700 mb-8">
            El estrés es una respuesta natural del cuerpo, pero cuando se vuelve constante, puede afectar nuestra salud física y emocional. Afortunadamente, hay varias estrategias que puedes poner en práctica para reducirlo y mantenerte en equilibrio. Aquí te ofrecemos algunos consejos respaldados por expertos para gestionar tu estrés de manera efectiva:
          </p>

          <div className="flex flex-col md:flex-row md:space-x-8">
            <div className="flex-1 p-6 bg-white rounded-lg shadow-md hover:shadow-xl transition duration-300">
              <div className="flex items-center space-x-4 mb-4">
                <FaHeartbeat className="text-3xl text-blue-500" />
                <h3 className="text-2xl font-semibold text-gray-800">1. Practica la Respiración Profunda</h3>
              </div>
              <p className="text-gray-600">
                Cuando nos sentimos estresados o ansiosos, nuestra respiración tiende a volverse rápida y superficial. Un método sencillo pero poderoso para calmarte en esos momentos es la respiración profunda. Esta técnica no solo aporta oxígeno a tu cuerpo, sino que también activa la respuesta de relajación de tu sistema nervioso.
              </p>

              <p className="text-gray-600 mt-3">
                <span className="font-semibold">¿Cómo hacerlo? </span>Siéntate o acuéstate en un lugar tranquilo, cierra los ojos e inhala profundamente por la nariz durante 4 segundos. Siente cómo el aire llena tus pulmones y abdomen. Luego, exhala lentamente por la boca durante 6 segundos. Repite este ciclo durante unos minutos y notarás cómo tu mente y cuerpo empiezan a relajarse.
              </p>
            </div>

            <div className="flex-1 p-6 bg-white rounded-lg shadow-md hover:shadow-xl transition duration-300 mt-3">
              <div className="flex items-center space-x-4 mb-4">
                <FaRunning className="text-3xl text-green-500" />
                <h3 className="text-2xl font-semibold text-gray-800">2. Realiza Ejercicio Regular</h3>
              </div>
              <p className="text-gray-600">
                El ejercicio no solo fortalece tu cuerpo, sino que es uno de los mejores antídotos contra el estrés. Al realizar actividad física, ya sea una caminata ligera, correr, practicar yoga o levantar pesas, tu cerebro libera endorfinas, las hormonas que te hacen sentir bien. Además, el ejercicio ayuda a liberar tensiones acumuladas y mejora la calidad del sueño.
              </p>

              <p className="text-gray-600 mt-3">
                <span className="font-semibold">Sugerencia: </span>El ejercicio no solo fortalece tu cuerpo, sino que es uno de los mejores antídotos contra el estrés. Al realizar actividad física, ya sea una caminata ligera, correr, practicar yoga o levantar pesas, tu cerebro libera endorfinas, las hormonas que te hacen sentir bien. Además, el ejercicio ayuda a liberar tensiones acumuladas y mejora la calidad del sueño.
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:space-x-8">
            <div className="flex-1 p-6 bg-white rounded-lg shadow-md hover:shadow-xl transition duration-300 mt-3">
              <div className="flex items-center space-x-4 mb-4">
                <FaBed className="text-3xl text-purple-500" />
                <h3 className="text-2xl font-semibold text-gray-800">3. Duerme Bien</h3>
              </div>
              <p className="text-gray-600">
                El descanso adecuado es fundamental para mantener a raya el estrés. Durante el sueño, tu cuerpo y mente se recuperan de las tensiones diarias. Sin embargo, el estrés puede alterar tu capacidad para dormir bien, creando un círculo vicioso. Establecer una rutina de sueño regular y asegurarte de dormir entre 7 y 9 horas cada noche puede ser clave para reducir los niveles de estrés.
              </p>

              <ul className="list-disc list-inside text-gray-600 mt-3">
                <li className="mb-2">Crea un ambiente tranquilo y oscuro en tu habitación.</li>
                <li className="mb-2">Evita las pantallas y dispositivos electrónicos al menos una hora antes de acostarte.</li>
                <li className="mb-2">Practica una rutina relajante antes de dormir, como leer o tomar un baño caliente.</li>
              </ul>
            </div>

            <div className="flex-1 p-6 bg-white rounded-lg shadow-md hover:shadow-xl transition duration-300 mt-3">
              <div className="flex items-center space-x-4 mb-4">
                <FaCommentDots className="text-3xl text-orange-500" />
                <h3 className="text-2xl font-semibold text-gray-800">4. Habla con Alguien</h3>
              </div>
              <p className="text-gray-600">
                No subestimes el poder de una buena conversación. A veces, cuando mantenemos nuestras preocupaciones o tensiones en silencio, estas pueden acumularse y aumentar el estrés. Hablar con un amigo cercano, un familiar o un profesional de la salud mental te puede ayudar a liberar esa carga emocional y ver tus problemas desde una nueva perspectiva.
              </p>

              <p className="text-gray-600 mt-3">
                <span className="font-semibold">Recuerda: </span>No tienes que enfrentar el estrés solo. Expresar tus sentimientos no solo te ayuda a desahogarte, sino que también puede acercarte a soluciones o formas de manejo que no habías considerado.
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:space-x-8">
            <div className="flex-1 p-6 bg-white rounded-lg shadow-md hover:shadow-xl transition duration-300 mt-3">
              <div className="flex items-center space-x-4 mb-4">
                <FaMedapps className="text-3xl text-teal-500" />
                <h3 className="text-2xl font-semibold text-gray-800">5. Practica la Atención Plena (Mindfulness)</h3>
              </div>
              <p className="text-gray-600">
                En lugar de preocuparte por el futuro o revivir el pasado, puedes aprender a observar tus pensamientos y emociones sin juzgarlos, lo que reduce el impacto del estrés en tu vida diaria.
              </p>

              <p className="text-gray-600 mt-3">
                <span className="font-semibold">¿Cómo empezar? </span>Dedica unos minutos cada día a sentarte en un lugar tranquilo. Cierra los ojos y enfócate en tu respiración, dejando pasar los pensamientos sin aferrarte a ellos. Con el tiempo, esta práctica te permitirá ser más consciente de tus emociones y manejar el estrés con mayor calma.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="mt-8"> {/* Ajuste para dar espacio antes del footer */}
        <Footer />
      </div>
    </div>
  );
};

export default ManageStress;
