import React, { useState } from 'react';
import { PiUserCircle } from "react-icons/pi";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { setUser, setToken } from '../redux/userSlice';

// Importamos el HeaderInicio desde la ruta correcta
import HeaderInicio from '../components/Extras/HeaderInicio';

const CheckEmailPage = () => {
  const [data, setData] = useState({
    email: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const handleGoogleSuccess = async (credentialResponse) => {
    const decoded = jwtDecode(credentialResponse.credential);
    console.log('Google JWT Decoded:', decoded);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/google-login`, {
        email: decoded.email,
        name: decoded.name,
        profile_pic: decoded.picture,
      });

      console.log('Respuesta del backend:', response);  // Verifica que el token esté en la respuesta

      if (response.data.success) {
        const token = response.data.token;  // Aquí debe estar el token

        if (token) {
          console.log('Token recibido:', token);  // Verifica que el token esté disponible aquí

          dispatch(setUser({
            _id: response.data.data._id,
            name: response.data.data.name,
            email: response.data.data.email,
            profile_pic: response.data.data.profile_pic,
            role: 'user', // Asegura que el rol se guarde como 'user'

          }));

          dispatch(setToken(token));
          localStorage.setItem('token', token);  // Almacena el token en localStorage

          toast.success('Inicio de sesión exitoso');
          navigate('/app/home');
        } else {
          toast.error('Error: El token no está disponible');
        }
      } else {
        toast.error('Error al iniciar sesión');
      }
    } catch (error) {
      toast.error('Error al procesar la solicitud');
    }
  };






  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const URL = `${process.env.REACT_APP_BACKEND_URL}/api/email`;

    try {
      const response = await axios.post(URL, data);
      toast.success(response.data.message);

      if (response.data.success) {
        setData({
          email: "",
        });
        navigate('/app/password', {
          state: response?.data?.data,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <HeaderInicio />
      <div className='flex items-center justify-center min-h-screen bg-gray-100'>
        <div className='bg-white w-full max-w-md rounded-lg overflow-hidden p-6 shadow-lg'>
          <div className='w-fit mx-auto mb-4'>
            <PiUserCircle size={80} className='text-gray-600' />
          </div>
          <h3 className='text-center text-xl font-semibold text-gray-700 mb-4'>¡Bienvenido a tu espacio!</h3>

          {/* Botón de Google Login */}
          <div className='flex justify-center mb-6'>
            <div style={{ transform: 'scale(1.5)', padding: '10px' }}>
              <GoogleLogin
                onSuccess={handleGoogleSuccess}
                onError={() => {
                  console.log('Login Failed');
                }}
              />
            </div>
          </div>

          <div className='text-center text-gray-600 my-2'><span>o</span></div>

          <form className='grid gap-4' onSubmit={handleSubmit}>
            <div className='flex flex-col gap-1'>
              <label htmlFor='email' className='text-gray-700'>Correo:</label>
              <input
                type='email'
                id='email'
                name='email'
                placeholder='Ingresa tu correo electrónico'
                className='bg-slate-100 px-3 py-2 focus:outline-primary border border-gray-300 rounded-md'
                value={data.email}
                onChange={handleOnChange}
                required
              />
            </div>

            <button type="submit2" className='bg-green-500 text-lg px-4 py-2 hover:bg-green-700 rounded font-bold text-white'>
              Iniciar Sesión
            </button>
          </form>

          <p className='my-4 text-center text-gray-600'>
            ¿Usuario nuevo? <Link to={"/app/register"} className='hover:text-green-500 font-semibold'>Registrarse</Link>
          </p>

          {/* Botón estilizado para "Soy Psicólogo" */}
          <div className='text-center mt-6'>
            <button
              className='bg-gradient-to-r from-green-500 to-teal-500 text-lg px-5 py-2.5 transition-transform duration-300 ease-in-out transform hover:scale-105 active:scale-95 hover:bg-gradient-to-r hover:from-blue-500 hover:to-indigo-500 rounded-full font-bold text-white shadow-lg hover:shadow-xl'
              onClick={() => navigate('/PsychologistLogin')}
            >
              Soy Psicolgo(a)
            </button>
          </div>

        </div>
      </div>
    </>
  );
};

export default CheckEmailPage;
