import React, { useEffect } from 'react';  
import Footer from '../Extras/Footer';
import Header from '../Extras/HeaderInicio';

const PrivacyPolicy = () => {
  useEffect(() => {
    // Forzar el desplazamiento al inicio cuando se carga el componente
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="min-h-screen bg-gray-50 flex flex-col items-center p-6 md:p-12">
        <div className="w-full max-w-4xl bg-white shadow-lg rounded-lg p-8 md:p-16">
          <h1 className="text-5xl font-bold text-gray-900 mb-4 text-center">Política de Privacidad</h1>
          <p className="text-gray-600 text-lg text-justify mb-8">
            En <span className="font-semibold">TuEspacio</span>, nos comprometemos a proteger tu información personal. Esta Política de Privacidad describe cómo recopilamos, utilizamos, compartimos y protegemos tus datos personales, cumpliendo con la Ley N° 29733 (Ley de Protección de Datos Personales), su Reglamento y Directiva.
          </p>

          <section className="mb-8">
            <h2 className="text-4xl font-semibold text-gray-800 mb-4">Alcance</h2>
            <p className="text-gray-600 text-justify mb-8">
              Esta política se aplica a los datos personales que recopilamos a través de nuestros medios digitales (sitio web, chatbot, aplicaciones móviles), así como a los servicios que brindamos por teléfono u otros medios disponibles para nuestros USUARIOS. En este contexto, un USUARIO es cualquier persona que interactúe con <span className="font-semibold">TuEspacio</span> y nos haya proporcionado su información personal.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-4xl font-semibold text-gray-800 mb-4">Consideraciones Generales</h2>
            <p className="text-gray-600 text-justify mb-8">
              Al registrarte en nuestras plataformas digitales, declaras estar informado acerca del tratamiento de tus datos personales y otorgas tu consentimiento libre y expreso para dicho tratamiento. Es importante mencionar que trabajamos con terceros para la prestación de algunos de nuestros servicios, lo que implica que algunos datos podrían compartirse bajo estrictos protocolos de seguridad.
            </p>
            <p className="text-gray-600 text-justify mb-8">
              Nuestra Política de Privacidad no cubre sitios web de terceros que puedas visitar a través de enlaces desde nuestra plataforma.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-4xl font-semibold text-gray-800 mb-4">Uso Autorizado de Datos Personales</h2>
            <p className="text-gray-600 text-justify">
              <span className="font-semibold">TuEspacio</span> está autorizado, en virtud de la Ley N° 29733, a tratar los datos personales que nos proporcionas, tanto de manera digital como física, para garantizar la correcta prestación de nuestros servicios. Algunos de estos datos incluyen información sobre tu salud mental y emocional, la cual es necesaria para ofrecerte atención psicológica, ya sea a través de profesionales o practicantes.
            </p>
            <p className="text-gray-600 text-justify">
              Es fundamental que la información personal que nos proporcionas se mantenga actualizada durante toda tu relación con nosotros. Si optas por no proporcionar ciertos datos esenciales, esto podría afectar la calidad o disponibilidad de nuestros servicios.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-4xl font-semibold text-gray-800 mb-4">Finalidades del Tratamiento de Datos</h2>
            <ul className="list-disc list-inside text-gray-600 mt-2">
              <li className="mb-2">Proporcionar los servicios contratados, incluyendo el soporte psicológico.</li>
              <li className="mb-2">Evaluar la calidad de nuestros servicios con el fin de mejorarlos.</li>
              <li className="mb-2">Mejorar nuestras plataformas digitales, como el chatbot y los servicios psicológicos que ofrecemos.</li>
              <li className="mb-2">Realizar estudios estadísticos y de comportamiento de los usuarios para optimizar su experiencia en nuestra plataforma.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-4xl font-semibold text-gray-800 mb-4">Derechos de los Usuarios sobre sus Datos (Derechos ARCO)</h2>
            <p className="text-gray-600 text-justify">
              Como USUARIO, tienes pleno derecho a ejercer tu control sobre los datos personales que nos proporcionas. Entre los derechos reconocidos por la Ley N° 29733 se incluyen:
            </p>
            <ul className="list-disc list-inside text-gray-600 mt-2">
              <li className="mb-2">Solicitar acceso a la información que tenemos sobre ti, para conocer qué datos personales hemos almacenado.</li>
              <li className="mb-2">Pedir la rectificación de tus datos si detectas que son inexactos o necesitan ser actualizados.</li>
              <li className="mb-2">Solicitar la cancelación de tus datos personales cuando ya no sean necesarios para los fines para los que fueron recopilados, o cuando la ley lo permita.</li>
              <li className="mb-2">Oponerte al tratamiento de tus datos en caso de que existan motivos legítimos que lo justifiquen.</li>
            </ul>
            <p className="text-gray-600 text-justify">
              Para ejercer cualquiera de estos derechos o revocar tu consentimiento para el tratamiento de datos, puedes enviar una solicitud a través de nuestras plataformas digitales o escribirnos al correo electrónico <span className="font-semibold">info@tuespacio.com</span>.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-4xl font-semibold text-gray-800 mb-4">Protección y Seguridad de los Datos</h2>
            <p className="text-gray-600 text-justify">
              En <span className="font-semibold">TuEspacio</span>, implementamos medidas técnicas y organizativas adecuadas para proteger tus datos personales frente a accesos no autorizados, pérdidas o alteraciones. Nuestras políticas de seguridad están diseñadas para garantizar la confidencialidad e integridad de tu información personal.
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
