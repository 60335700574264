import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../../assets/logo.png"; // Ruta actualizada

function Footer() {
  return (
    <footer className="bg-white py-8 border-t">
      <div className="container mx-auto px-6">
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center">

          {/* Left Section - Logo y redes sociales */}
          <div className="lg:w-1/4 text-center lg:text-left mt-6 lg:mt-0">
            {/* Logo */}
            <a href="#" className="text-xl font-bold text-blue-500">
              <img 
                src={logo} 
                alt="Logo TuEspacio" 
                className="mx-auto lg:mx-0"
                style={{ width: '120px', height: 'auto', marginBottom: '10px' }} 
              />
            </a>

            {/* Social Icons */}
            <div className="flex justify-center lg:justify-start space-x-4 mt-5">
              <a href="#" aria-label="Facebook">
                <i className="fa fa-facebook" style={{ fontSize: '35px', color: '#gray-500' }} />
              </a>
              <a href="#" aria-label="Instagram">
                <i className="fa fa-instagram" style={{ fontSize: '35px', color: '#gray-500' }} />
              </a>
              <a href="#" aria-label="Twitter">
                <i className="fa fa-twitter" style={{ fontSize: '35px', color: '#gray-500' }} />
              </a>
              <a href="#" aria-label="YouTube">
                <i className="fa fa-youtube" style={{ fontSize: '35px', color: '#gray-500' }} />
              </a>
            </div>
          </div>

          {/* Right Section - Links */}
          <div className="flex flex-wrap lg:flex-nowrap justify-between lg:justify-start mt-6 lg:mt-0 space-y-6 lg:space-y-0 lg:space-x-12 w-full lg:w-auto">
            <div className="w-1/2 lg:w-auto">
              <h5 className="text-gray-900 font-bold">Atención psicológica</h5>
              <ul className="mt-2 space-y-2 text-gray-500">
                <li><Link to="/infoansiedad" className="hover:text-gray-900">Ansiedad</Link></li>
                <li><Link to="/infoestres" className="hover:text-gray-900">Estrés</Link></li>
              </ul>
            </div>

            <div className="w-1/2 lg:w-auto">
              <h5 className="text-gray-900 font-bold">Recursos</h5>
              <ul className="mt-2 space-y-2 text-gray-500">
                <li><Link to="#" className="hover:text-gray-900">Artículos sobre ansiedad</Link></li>
                <li><Link to="/gestionarestres" className="hover:text-gray-900">Cómo gestionar el estrés</Link></li>
              </ul>
            </div>

            <div className="w-1/2 lg:w-auto">
              <h5 className="text-gray-900 font-bold">Apoyo</h5>
              <ul className="mt-2 space-y-2 text-gray-500">
                <li><Link to="/Infoapoyopracticantes" className="hover:text-gray-900">Apoyo con Practicantes</Link></li>
                <li><Link to="#" className="hover:text-gray-900">Apoyo con Profesionales</Link></li>
                <li><Link to="#" className="hover:text-gray-900">Chatbot</Link></li>
                <li><Link to="#" className="hover:text-gray-900">¿Por qué nosotros?</Link></li>
              </ul>
            </div>

            <div className="w-1/2 lg:w-auto">
              <h5 className="text-gray-900 font-bold">Compañía</h5>
              <ul className="mt-2 space-y-2 text-gray-500">
                <li><Link to="/nosotros" className="hover:text-gray-900">Nosotros</Link></li>
                <li><Link to="/#trabaja-con-nosotros" className="hover:text-gray-900">Trabaja con nosotros</Link></li>
                <li><Link to="/creadores" className="hover:text-gray-900">Creadores</Link></li>
              </ul>
            </div>

            <div className="w-1/2 lg:w-auto">
              <h5 className="text-gray-900 font-bold">Legal</h5>
              <ul className="mt-2 space-y-2 text-gray-500">
                <li><Link to="/privacidad" className="hover:text-gray-900">Política de privacidad</Link></li>
                <li><Link to="/terminos" className="hover:text-gray-900">Términos y Condiciones</Link></li>
                <li><Link to="/LegalCitas" className="hover:text-gray-900">Política de citas</Link></li>
              </ul>
            </div>

            <div className="w-1/2 lg:w-auto">
              <h5 className="text-gray-900 font-bold">Contacto</h5>
              <ul className="mt-2 space-y-2 text-gray-500">
                <li><a href="mailto:support@tuespacio.com" className="hover:text-gray-900">Correo electrónico</a></li>
                <li><a href="mailto:support@tuespacio.com" className="hover:text-gray-900">Contacto por Whatsapp</a></li>
              </ul>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-8 border-t pt-6 text-center">
          <p className="text-gray-500">
            © 2024 TuEspacio, Inc. Todos los derechos reservados.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
