// src/PsicologoCrud.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PsicologoCrud.css';

const PsicologoCrud = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [psychologists, setPsychologists] = useState([]);
  const [editingId, setEditingId] = useState(null); // Para editar
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Cargar psicólogos al montar el componente
  useEffect(() => {
    fetchPsychologists();
  }, []);

  // Leer todos los psicólogos
  const fetchPsychologists = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/psychologists`);
      setPsychologists(response.data);
    } catch (error) {
      console.error(error);
      setError('Error al cargar los psicólogos');
    }
  };

  // Crear o actualizar psicólogo
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      if (editingId) {
        // Actualizar
        await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/psychologists/${editingId}`, { name, email });
        setSuccess('Psicólogo actualizado exitosamente');
      } else {
        // Crear
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/psychologists`, { name, email, password });
        setSuccess(response.data.message); // Mensaje del servidor
      }
      resetForm();
      fetchPsychologists(); // Recargar lista
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 400) {
        // Mensaje específico de validación desde el servidor
        setError(error.response.data.message);
      } else {
        setError('Error al guardar el psicólogo');
      }
    }
  };

  // Confirmación antes de eliminar psicólogo
  const handleDelete = async (id) => {
    const confirmed = window.confirm('¿Estás seguro de que deseas eliminar este psicólogo?');
    if (!confirmed) return;

    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/psychologists/${id}`);
      setSuccess('Psicólogo eliminado exitosamente');
      fetchPsychologists(); // Recargar lista
    } catch (error) {
      console.error(error);
      setError('Error al eliminar el psicólogo');
    }
  };

  // Preparar para editar
  const handleEdit = (psychologist) => {
    setName(psychologist.name);
    setEmail(psychologist.email);
    setEditingId(psychologist._id);
  };

  // Reiniciar el formulario
  const resetForm = () => {
    setName('');
    setEmail('');
    setPassword('');
    setEditingId(null);
  };

  return (
    <div className="psicologo-crud">
      <h2>{editingId ? 'Editar Psicólogo' : 'Crear Psicólogo'}</h2>
      <form onSubmit={handleSubmit} className="psicologo-crud-form">
        <div className="form-group">
          <label htmlFor="name">Nombre:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Correo Electrónico:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        {!editingId && (
          <div className="form-group">
            <label htmlFor="password">Contraseña:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
        )}
        <button type="submit">{editingId ? 'Actualizar' : 'Crear'}</button>
        <button type="button" onClick={resetForm} className="cancel-button">
          Cancelar
        </button>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
      </form>

      {/* Listado de Psicólogos */}
      <h3>Lista de Psicólogos</h3>
      <table className="psychologist-table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Correo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {psychologists.map((psychologist) => (
            <tr key={psychologist._id}>
              <td>{psychologist.name}</td>
              <td>{psychologist.email}</td>
              <td>
                <button onClick={() => handleEdit(psychologist)}>Editar</button>
                <button onClick={() => handleDelete(psychologist._id)} className="delete-button">Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PsicologoCrud;
