import React, { useEffect } from 'react';  
import Footer from '../Extras/Footer';
import Header from '../Extras/HeaderInicio';

const LegalCitas = () => {
  useEffect(() => {
    // Forzar el desplazamiento al inicio cuando se carga el componente
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="min-h-screen bg-gray-50 flex flex-col items-center p-6 md:p-12">
        <div className="w-full max-w-4xl bg-white shadow-lg rounded-lg p-8 md:p-16">
          <h1 className="text-5xl font-bold text-gray-900 mb-4 text-center">Política de Citas</h1>
          <p className="text-gray-600 text-lg text-justify mb-8">
            Gracias por confiar en <span className="font-semibold">TuEspacio</span>. Nuestra misión es ayudarte a encontrar el equilibrio emocional que necesitas en tu vida diaria. Para que saques el mayor provecho de este proceso, aquí te compartimos algunos puntos importantes:
          </p>

          <ul className="list-disc list-inside text-gray-600 mb-8">
            
            <li className="mb-2">La terapia en línea no sustituye la atención médica o psiquiátrica ni es un servicio de emergencia. Si te encuentras en una crisis o emergencia, por favor comunícate con los servicios de emergencia de tu localidad.</li>
            <li className="mb-2">Todas las citas agendadas se cancelarán automáticamente 8 horas antes de comenzar si no se confirma o realiza el pago. También puedes agendar citas hasta con 3 horas de antelación, siempre que realices el pago o confirmación de inmediato.</li>
            <li className="mb-2">En <span className="font-semibold">TuEspacio</span>, nos comprometemos a brindarte un entorno seguro con psicoterapeutas profesionales, éticos y responsables. Recuerda realizar siempre el pago a través de nuestra plataforma. Si un terapeuta te sugiere un pago fuera de <span className="font-semibold">TuEspacio</span>, te invitamos a reportarlo y aportar evidencia. Podrás recibir una recompensa como agradecimiento.</li>
          </ul>

          <h2 className="text-4xl font-semibold text-gray-800 mb-4">Sobre el proceso de tomar terapia</h2>
          <ul className="list-disc list-inside text-gray-600 mb-8">
            <li className="mb-2">Te recomendamos realizar tus sesiones en un lugar cómodo y privado para maximizar los beneficios de la terapia.</li>
            <li className="mb-2">La terapia es un proceso que requiere constancia: el 80% de la efectividad depende de ti, y el 20% del psicólogo. Con paciencia, comenzarás a notar resultados.</li>
            <li className="mb-2">Comprométete a no presentarte a tu cita bajo los efectos del alcohol o drogas. Si lo haces, el psicólogo podrá cancelar la sesión sin derecho a reembolso.</li>
          </ul>

          <h2 className="text-4xl font-semibold text-gray-800 mb-4">Política de cambios y cancelaciones</h2>
          <ul className="list-disc list-inside text-gray-600 mb-8">
            <li className="mb-2">Puedes modificar tu cita hasta 8 horas antes de su inicio. Pasado este tiempo, no podrás realizar modificaciones ni cancelaciones.</li>
            <li className="mb-2">Se permite cambiar la fecha y hora de una cita un máximo de 3 veces desde tu cuenta.</li>
            <li className="mb-2">Si surge una emergencia de último minuto, como enfermedades o problemas con la conexión a internet, contacta a tu terapeuta a través de la plataforma o con nosotros para ayudarte.</li>
            <li className="mb-2">Si olvidas tu cita o no te presentas, se cobrará en su totalidad sin opción a reembolso o reprogramación.</li>
            <li className="mb-2">El psicólogo esperará tu llegada hasta 20 minutos después del inicio de la sesión. Si no te presentas, se considerará completada.</li>
          </ul>

          <h2 className="text-4xl font-semibold text-gray-800 mb-4">Reembolsos</h2>
          <ul className="list-disc list-inside text-gray-600 mb-8">
            <li className="mb-2">Si solicitaste un reembolso y pagaste con tarjeta,yape o plin, el reembolso será automático a la cuenta de origen. Para pagos por transferencia, se procesará en un máximo de 72 horas.</li>
            <li className="mb-2">Tienes hasta 48 horas desde el pago para solicitar un reembolso.</li>
          </ul>

          <h2 className="text-4xl font-semibold text-gray-800 mb-4">Política de paquetes de citas</h2>
          <ul className="list-disc list-inside text-gray-600 mb-8">
            <li className="mb-2">Los paquetes de citas tienen una vigencia de 12 meses desde la fecha de compra. Puedes extender la vigencia por un máximo de 6 meses pagando cualquier diferencia de precio. Las citas no tomadas en este periodo se perderán.</li>
            <li className="mb-2">Los paquetes no tienen opción a reembolso, pero pueden transferirse a otra persona bajo las condiciones mencionadas.</li>
          </ul>

          <h2 className="text-4xl font-semibold text-gray-800 mb-4">Garantía de primera cita</h2>
          <p className="text-gray-600 text-justify mb-4">
            En <span className="font-semibold">TuEspacio</span>, te ofrecemos una Garantía de Primera Cita para asegurarnos de que encuentres a tu terapeuta ideal. Esta garantía te permite tomar tu primera cita con otro terapeuta sin costo adicional si la solicitas dentro de las 72 horas posteriores a tu primera sesión.
          </p>

          <p className="text-gray-600 text-justify mb-4">Para más información o asistencia, no dudes en contactarnos.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LegalCitas;
