// src/components/PsychologistSidebar.js
import React, { useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { BiLogOut, BiHome, BiUser } from 'react-icons/bi';
import { IoChatbubbleEllipses } from 'react-icons/io5';
import Avatar from './Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/userSlice';
import EditPsychologistDetails from './Psychologist/EditPsychologistDetails';

const PsychologistSidebar = ({ onChatClick }) => {
  const psychologist = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [editUserOpen, setEditUserOpen] = useState(false);
  const isMessagePage = location.pathname.includes("/psychologist/messages");

  const handleLogout = () => {
    localStorage.removeItem('token');
    dispatch(logout());
    navigate('/PsychologistLogin');
  };

  return (
    <>
      <div className={`hidden lg:flex fixed left-0 top-0 h-full w-24 bg-white shadow-lg flex-col items-center py-4 z-50`}>
        <NavLink to="/psychologist/dashboard" className="mb-8" title="Dashboard">
          <BiHome size={24} className="hover:text-blue-500" />
        </NavLink>
        <div title="Chat" onClick={onChatClick} className="mb-8 cursor-pointer">
          <IoChatbubbleEllipses size={24} className="hover:text-blue-500" />
        </div>
        <NavLink to="/psychologist/profile" title="Perfil" className="mb-8">
          <BiUser size={24} className="hover:text-blue-500" />
        </NavLink>
        <button
          title="Editar Perfil"
          onClick={() => setEditUserOpen(true)}
          className="mx-auto"
        >
          <Avatar width={40} height={40} name={psychologist.name} imageUrl={psychologist.profile_pic} />
        </button>
        <button
          title="Cerrar sesión"
          className="mt-auto cursor-pointer hover:bg-gray-200 rounded-full p-2"
          onClick={handleLogout}
        >
          <BiLogOut size={24} className="hover:text-red-500" />
        </button>
      </div>

      {!isMessagePage && (
        <div className="lg:hidden fixed bottom-0 w-full bg-white shadow-lg flex justify-around items-center py-2 z-50">
          <NavLink to="/psychologist/dashboard" title="Dashboard">
            <BiHome size={24} className="hover:text-blue-500" />
          </NavLink>
          <div title="Chat" onClick={onChatClick} className="cursor-pointer">
            <IoChatbubbleEllipses size={24} className="hover:text-blue-500" />
          </div>
          <NavLink to="/psychologist/profile" title="Perfil">
            <BiUser size={24} className="hover:text-blue-500" />
          </NavLink>
          <button onClick={() => setEditUserOpen(true)} title={psychologist.name}>
            <Avatar width={40} height={40} name={psychologist.name} imageUrl={psychologist.profile_pic} />
          </button>
          <button onClick={handleLogout} title="Cerrar sesión" className="cursor-pointer">
            <BiLogOut size={24} className="hover:text-red-500" />
          </button>
        </div>
      )}

      {editUserOpen && (
        <EditPsychologistDetails onClose={() => setEditUserOpen(false)} user={psychologist} />
      )}
    </>
  );
};

export default PsychologistSidebar;
