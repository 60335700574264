// src/components/GatitoAnimado.js

import React from "react";

const GatitoAnimado = () => {
  return (
    <div
      className="fixed bottom-0 left-0 z-50 " // Colocamos al gatito en la esquina inferior izquierda
      style={{
        position: "fixed",
      }}
    >
      <dotlottie-player
        src="https://lottie.host/f76d0f4f-4afe-45fe-8aa2-f2b95716d4ed/pmbJRcyqEi.json"
        background="transparent"
        speed="1"
        style={{ width: "200px", height: "200px" }}
        loop
        autoplay
      ></dotlottie-player>
    </div>
  );
};

export default GatitoAnimado;
