// src/AdminDashboard.js
import React, { useState } from 'react';
import './AdminDashboard.css';
import PsicologoCrud from './PsicologoCrud';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
  const [activeSection, setActiveSection] = useState(''); 
  const navigate = useNavigate();

  const handleMenuClick = (section) => {
    setActiveSection(section);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/');
  };

  return (
    <div className="dashboard-container">
      <aside className="sidebar">
        <h2 className="sidebar-title">Panel de Control</h2>
        <ul className="sidebar-menu">
          <li className="sidebar-item" onClick={() => handleMenuClick('PsicologoCrud')}>Crear Psicólogos</li>
          <li className="sidebar-item" onClick={() => handleMenuClick('verUsuarios')}>Ver Usuarios</li>
          <li className="sidebar-item" onClick={() => handleMenuClick('configuracion')}>Configuración</li>
          <li className="sidebar-item" onClick={() => handleMenuClick('reportes')}>Reportes</li>
        </ul>
        <button className="logout-button" onClick={handleLogout}>Salir</button>
      </aside>
      <main className="dashboard-main">
        <h1 className="dashboard-title">Bienvenido, Admin</h1>
        {activeSection === 'PsicologoCrud' && <PsicologoCrud />}
      </main>
    </div>
  );
};

export default AdminDashboard;
