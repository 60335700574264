// src/components/ProtectedRoute.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, role }) => {
  const user = useSelector((state) => state.user);
  const token = localStorage.getItem('token');

  // Redirigir si el token no existe
  if (!token) {
    if (role === 'admin') return <Navigate to="/admin" replace />;
    if (role === 'psychologist') return <Navigate to="/PsychologistLogin" replace />;
    return <Navigate to="/app/email" replace />;
  }

  // Verificar el rol del usuario
  if (role && user.role !== role) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
