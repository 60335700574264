import React from 'react';
import Avatar from './Avatar';
import { Link } from 'react-router-dom';

const UserSearchCard = ({ user, onClose, showAboutMe = true }) => {
  const handleClick = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className="flex flex-col sm:flex-row sm:items-center lg:flex-row lg:items-start gap-6 p-6 border border-gray-200 rounded-lg bg-white shadow-sm max-w-2xl mx-auto hover:shadow-lg transition-shadow duration-300">
      {/* Sección de Avatar e información */}
      <Link
        to={`/app/home/${user?._id}`}
        onClick={handleClick}
        className="flex flex-row sm:flex-col sm:items-start items-center gap-4"
      >
        <div>
          <Avatar
            width={60}
            height={60}
            name={user?.name}
            userId={user?._id}
            imageUrl={user?.profile_pic}
          />
        </div>
        <div className="text-left">
          <div className="font-semibold text-lg text-gray-800">
            {user?.name}
          </div>
          <p className="text-sm text-gray-500">
            {user?.email}
          </p>
        </div>
      </Link>

      {showAboutMe && (  
        <div className="bg-gray-100 p-4 rounded-lg shadow-md max-w-sm">
          <h4 className="text-md font-bold text-gray-700 mb-2">Sobre mí</h4>
          <p className="text-sm text-gray-600">
            Especializado en terapia cognitivo-conductual y resolución de conflictos.
          </p>
        </div>
      )}
    </div>
  );
};

export default UserSearchCard;
