// src/pages/PsychologistMessages.js
import React from 'react';

const PsychologistMessages = () => {
  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Mensajes</h1>
      <p>Aquí puedes ver y responder los mensajes de tus usuarios.</p>
      {/* Implementar funcionalidad de mensajes aquí */}
    </div>
  );
};

export default PsychologistMessages;
