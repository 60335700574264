import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Avatar from "./Avatar";
import { HiDotsVertical } from "react-icons/hi";
import { FaAngleLeft, FaPlus, FaImage, FaVideo } from "react-icons/fa6";
import uploadFile from "../helpers/uploadFile";
import { IoMdSend } from "react-icons/io";
import backgroundImage from "../assets/wallpaper.webp";
import dayjs from 'dayjs'; // Reemplazando Moment.js por Day.js

import "./messagePage.css";  // Asegúrate de importar el CSS actualizado

const MessagePage = () => {
  const params = useParams();
  const socketConnection = useSelector((state) => state?.user?.socketConnection);
  const user = useSelector((state) => state?.user);
  const [dataUser, setDataUser] = useState({
    name: "",
    email: "",
    profile_pic: "",
    online: false,
    _id: "",
  });
  const [openImageVideoUpload, setOpenImageVideoUpload] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    imageUrl: "",
    videoUrl: "",
  });
  const [loading, setLoading] = useState(false);
  const [allMessage, setAllMessage] = useState([]);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [allMessage]);

  useEffect(() => {
    if (socketConnection && params.userId) {
      socketConnection.emit("message-page", params.userId);

      socketConnection.emit("seen", params.userId);

      socketConnection.on("message-user", (data) => {
        setDataUser(data);
      });

      socketConnection.on("message", (data) => {
        setAllMessage(data);
      });
    }
  }, [socketConnection, params?.userId, user]);

  const handleOnChange = (e) => {
    const { value } = e.target;

    setMessage((preve) => ({
      ...preve,
      text: value,
    }));
  };

  const handleSendMessage = (e) => {
    e.preventDefault();

    if (message.text || message.imageUrl || message.videoUrl) {
      if (socketConnection) {
        socketConnection.emit("new message", {
          sender: user?._id,
          receiver: params.userId,
          text: message.text,
          imageUrl: message.imageUrl,
          videoUrl: message.videoUrl,
          msgByUserId: user?._id,
        });
        setMessage({
          text: "",
          imageUrl: "",
          videoUrl: "",
        });
      }
    }
  };

  return (
    <div
      className="message-page"  // Asegura que usemos la clase para el contenedor completo
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <header className="sticky top-0 h-16 bg-white flex justify-between items-center px-4 z-10">
        <div className="flex items-center gap-4">
          <Link to={"/app/home"} className="lg:hidden">
            <FaAngleLeft size={25} />
          </Link>
          <div>
            <Avatar
              width={50}
              height={50}
              imageUrl={dataUser?.profile_pic}
              name={dataUser?.name}
              userId={dataUser?._id}
            />
          </div>
          <div>
            <h3 className="font-semibold text-lg my-0 text-ellipsis line-clamp-1">
              {dataUser?.name}
            </h3>
            <p className="-my-2 text-sm">
              {dataUser.online ? (
                <span className="text-primary">En línea</span>
              ) : (
                <span className="text-slate-400">Desconectado</span>
              )}
            </p>
          </div>
        </div>

        <div>
          <button
            className="cursor-pointer hover:text-primary"
            onClick={() => setOpenImageVideoUpload(!openImageVideoUpload)}
          >
            <HiDotsVertical />
          </button>
        </div>
      </header>

      <section className="messages-container">  {/* Contenedor que será desplazable */}
        <div className="flex flex-col gap-2 mx-2">
          {allMessage.map((msg, index) => (
            <div
              key={msg._id || index}
              className={`p-1 py-1 rounded w-fit max-w-[280px] md:max-w-sm lg:max-w-md ${
                user._id === msg?.msgByUserId ? "ml-auto bg-teal-100" : "bg-white"
              }`}
            >
              {msg?.imageUrl && (
                <img
                  src={msg?.imageUrl}
                  className="w-full h-full object-scale-down"
                  alt="uploaded"
                />
              )}
              {msg?.videoUrl && (
                <video
                  src={msg.videoUrl}
                  className="w-full h-full object-scale-down"
                  controls
                />
              )}
              <p className="px-2">{msg.text}</p>
              <p className="text-xs ml-auto w-fit">
                {dayjs(msg.createdAt).format("hh:mm")}
              </p>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      </section>

      <section className="message-input-section">  {/* Input siempre visible abajo */}
        <form className="flex gap-2" onSubmit={handleSendMessage}>
          <input
            type="text"
            placeholder="Escribe aquí un mensaje"
            className="py-1 px-4 outline-none flex-grow"
            value={message.text}
            onChange={handleOnChange}
          />
          <button className="text-primary hover:text-secondary">
            <IoMdSend size={28} />
          </button>
        </form>
      </section>
    </div>
  );
};

export default MessagePage;
