import React, { Suspense, useEffect } from "react";
import GatitoAnimado from "../components/Extras/GatitoAnimado"; // Importa el gatito animado

// Otros componentes
const HeaderInicio = React.lazy(() =>
  import("../components/Extras/HeaderInicio")
);
const HeroPage = React.lazy(() => import("../components/Inicio/HeroPage"));
const Stats = React.lazy(() => import("../components/Inicio/Stats"));
const Footer = React.lazy(() => import("../components/Extras/Footer"));
const PsychologistList = React.lazy(() =>
  import("../components/Inicio/PsychologistList")
);
const ChatbotSection = React.lazy(() =>
  import("../components/Inicio/ChatbotSection")
);
const ThreeStepsSection = React.lazy(() =>
  import("../components/Inicio/ThreeStepsSection")
);
const ServicesSection = React.lazy(() =>
  import("../components/Inicio/ServicesSection")
);
const WorkWithUsSection = React.lazy(() =>
  import("../components/Inicio/WorkWithUsSection")
);
const LaunchSection = React.lazy(() =>
  import("../components/Inicio/LaunchSection")
);
const WhyUsSection = React.lazy(() =>
  import("../components/Inicio/WhyUsSection")
);
const Chatbot = React.lazy(() => import("../components/Extras/chatbot"));

const Inicio = () => {
  useEffect(() => {
    const hash = window.location.hash;
      if (hash === "#trabaja-con-nosotros") {
        const section = document.getElementById("trabaja-con-nosotros");
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, []); // Se ejecuta una vez al montar el componente
  
  return (
    <div className="relative isolate bg-transparent">
      <HeaderInicio />
      <HeroPage />
      <Stats />

      <Suspense fallback={<div>Loading...</div>}>
        <ThreeStepsSection />
        <LaunchSection />
        <PsychologistList />
        <ServicesSection />
        <ChatbotSection />
        <WhyUsSection />
        <WorkWithUsSection />
        <Chatbot />
      </Suspense>

      {/* Añadimos la animación Lottie que se mueve aleatoriamente */}
      <GatitoAnimado /> {/* Aquí es donde colocas el gatito animado */}

      <Footer />
    </div>
  );
};

export default Inicio;
