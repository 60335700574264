// src/pages/PsychologistLogin.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setUser } from '../redux/userSlice';

const PsychologistLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Limpia el mensaje de error al intentar de nuevo
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/psychologists/login`, {
        email,
        password,
      });
      
      const { token, data } = response.data;
      
      if (token) {
        localStorage.setItem('token', token);
        dispatch(setUser({ ...data, role: 'psychologist', token })); // Guarda el rol en Redux
        toast.success('Inicio de sesión exitoso');
        navigate('/psychologist/dashboard'); // Navega al dashboard
      }
    } catch (error) {
      // Muestra el mensaje de error específico enviado por el servidor
      if (error.response) {
        setErrorMessage(error.response.data.message || 'Error en el inicio de sesión');
      } else {
        setErrorMessage('Error en el servidor. Inténtalo nuevamente más tarde.');
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-blue-100 to-purple-200">
      <div className="bg-white p-8 rounded-2xl shadow-2xl w-full max-w-md transform transition duration-300 hover:scale-105">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-extrabold text-gray-800 mb-2">Bienvenido Psicólogo</h2>
          <p className="text-gray-500 text-sm">
            Gracias por unirte a esta misión de ayudar a mejorar la vida de muchas personas.
          </p>
        </div>
        
        <form onSubmit={handleLogin} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-gray-700 font-semibold">Correo electrónico:</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Ingresa tu correo"
              className="w-full px-4 py-2 mt-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-gray-700 font-semibold">Contraseña:</label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Ingresa tu contraseña"
              className="w-full px-4 py-2 mt-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          
          {/* Muestra el mensaje de error si existe */}
          {errorMessage && (
            <p className="text-red-500 text-sm text-center mt-2">{errorMessage}</p>
          )}

          <button 
            type="submit" 
            className="w-full py-3 bg-gradient-to-r from-blue-500 to-purple-500 hover:from-purple-500 hover:to-blue-500 text-white font-bold rounded-lg shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105">
            Iniciar Sesión
          </button>
        </form>
        
        <p className="text-center text-gray-600 mt-6">
          ¿Olvidaste tu contraseña? <span className="text-blue-500 hover:text-blue-700 cursor-pointer">Recuperar acceso</span>
        </p>
      </div>
    </div>
  );
};

export default PsychologistLogin;
