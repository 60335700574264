// src/pages/PsychologistHome.js
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PsychologistSidebar from "../components/PsychologistSidebar";
import { setOnlineUser, setSocketConnection } from "../redux/userSlice";
import io from "socket.io-client";
import Confetti from "react-confetti";
import MessagesModal from "../components/MessagesModal";
import logo from "../assets/logotexto.png";

const PsychologistHome = () => {
  const psychologist = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();

  const [showConfetti, setShowConfetti] = useState(true);
  const [messagesModalOpen, setMessagesModalOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowConfetti(false), 3500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const socketConnection = io(process.env.REACT_APP_BACKEND_URL, {
      auth: {
        token: localStorage.getItem("token"),
      },
    });

    socketConnection.on("onlineUser", (data) => {
      dispatch(setOnlineUser(data));
    });

    dispatch(setSocketConnection(socketConnection));

    return () => {
      socketConnection.disconnect();
    };
  }, [dispatch]);

  const isMessagePage = location.pathname.includes("/psychologist/messages");
  const basePath = location.pathname === "/psychologist/dashboard";

  return (
    <div className="flex lg:flex-row min-h-screen h-full overflow-hidden">
      {showConfetti && (
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      )}

      {/* Sidebar */}
      <PsychologistSidebar onChatClick={() => setMessagesModalOpen(true)} />

      {/* Main Content */}
      <div className={`flex-1 p-4 pt-16 lg:pt-4 lg:ml-24 ${!isMessagePage ? 'overflow-y-auto' : 'overflow-hidden'} h-full`}>
        {basePath ? (
          <div className="text-center pb-12">
            <img src={logo} loading="lazy" alt="logo" className="mx-auto w-48" />
            <h1 className="text-2xl mt-4">Bienvenido, {psychologist.name}</h1>
            <div className="mt-6 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
              <h3 className="text-xl font-semibold">¡Novedades próximamente!</h3>
              <p>Pronto se podrá realizar videollamadas. Estamos trabajando en ello.</p>
            </div>
          </div>
        ) : (
          <Outlet />
        )}
      </div>

      <MessagesModal isOpen={messagesModalOpen} onClose={() => setMessagesModalOpen(false)} />
    </div>
  );
};

export default PsychologistHome;
