// src/AdminDashboard.js
import React, { useState } from 'react';
import './AdminDashboard.css';
import PsicologoCrud from './PsicologoCrud.js';
import { useNavigate } from 'react-router-dom'; // Asegúrate de que tienes react-router-dom instalado

const AdminDashboard = () => {
  const [activeSection, setActiveSection] = useState(''); // Controla la sección activa
  const navigate = useNavigate(); // Hook para la navegación

  const handleMenuClick = (section) => {
    setActiveSection(section);
  };

  // Función para manejar el cierre de sesión
  const handleLogout = () => {
    // Eliminar el token de autenticación (si lo tienes en localStorage o sessionStorage)
    localStorage.removeItem('authToken'); // O sessionStorage.removeItem('authToken');

    // Redirigir a la página de inicio de sesión
    navigate('/'); // Asegúrate de que esta ruta exista en tu aplicación
  };

  return (
    <div className="dashboard-container">
      <aside className="sidebar">
        <h2 className="sidebar-title">Panel de Control</h2>
        <ul className="sidebar-menu">
          <li className="sidebar-item" onClick={() => handleMenuClick('PsicologoCrud')}>Crear Psicólogos</li>
          <li className="sidebar-item" onClick={() => handleMenuClick('verUsuarios')}>Ver Usuarios</li>
          <li className="sidebar-item" onClick={() => handleMenuClick('configuracion')}>Configuración</li>
          <li className="sidebar-item" onClick={() => handleMenuClick('reportes')}>Reportes</li>
        </ul>
        {/* Botón de salir */}
        <button className="logout-button" onClick={handleLogout}>Salir</button>
      </aside>
      <main className="dashboard-main">
        <h1 className="dashboard-title">Bienvenido, Admin</h1>
        {/* Contenido de la sección activa */}
        {activeSection === 'PsicologoCrud' && <PsicologoCrud />}
        {/* Puedes agregar otros componentes para las demás secciones */}
      </main>
    </div>
  );
};

export default AdminDashboard;
