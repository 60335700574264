import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setUser } from '../redux/userSlice';

const LoginAdmin = () => {
  const [adminData, setAdminData] = useState({ username: '', password: '' });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdminData({ ...adminData, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/admin/login`, adminData);

      const token = response.data.token;
      dispatch(setUser({ token, role: 'admin' }));
      localStorage.setItem('adminToken', token);
      toast.success("Inicio de sesión exitoso");
      navigate('/dashboard');
    } catch (error) {
      toast.error(error.response?.data?.message || "Error en el inicio de sesión");
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f5f5f5' }}>
      <form onSubmit={handleLogin} style={{ padding: '2rem', borderRadius: '8px', backgroundColor: '#fff', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <h2 style={{ textAlign: 'center', marginBottom: '1rem', color: '#333' }}>Administrador</h2>
        
        <div style={{ marginBottom: '1rem' }}>
          <label htmlFor="username" style={{ display: 'block', marginBottom: '0.5rem', color: '#555' }}>Usuario</label>
          <input
            id="username"
            name="username"
            type="text"
            placeholder="Ingresa tu usuario"
            value={adminData.username}
            onChange={handleChange}
            style={{ width: '100%', padding: '0.75rem', borderRadius: '4px', border: '1px solid #ccc', fontSize: '1rem' }}
          />
        </div>

        <div style={{ marginBottom: '1.5rem' }}>
          <label htmlFor="password" style={{ display: 'block', marginBottom: '0.5rem', color: '#555' }}>Contraseña</label>
          <input
            id="password"
            name="password"
            type="password"
            placeholder="Ingresa tu contraseña"
            value={adminData.password}
            onChange={handleChange}
            style={{ width: '100%', padding: '0.75rem', borderRadius: '4px', border: '1px solid #ccc', fontSize: '1rem' }}
          />
        </div>

        <button type="submit" style={{
          width: '100%',
          padding: '0.75rem',
          borderRadius: '4px',
          backgroundColor: '#4CAF50',
          color: '#fff',
          fontSize: '1rem',
          border: 'none',
          cursor: 'pointer',
          transition: 'background-color 0.3s'
        }}
        onMouseOver={(e) => e.target.style.backgroundColor = '#45a049'}
        onMouseOut={(e) => e.target.style.backgroundColor = '#4CAF50'}
        >
          Iniciar Sesión
        </button>
      </form>
    </div>
  );
};

export default LoginAdmin;
