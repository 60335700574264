import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { IoClose } from 'react-icons/io5';
import { PiUserCircle } from "react-icons/pi";
import uploadFile from '../helpers/uploadFile';
import HeaderInicio from '../components/Extras/HeaderInicio';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { setUser, setToken } from '../redux/userSlice';

const RegisterPage = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    profile_pic: ""
  });
  const [uploadPhoto, setUploadPhoto] = useState("");
  const [isUploading, setIsUploading] = useState(false); // Estado de carga de foto
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado de carga de formulario
  const inputFileRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    const decoded = jwtDecode(credentialResponse.credential);
    console.log('Google JWT Decoded:', decoded);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/google-login`, {
        email: decoded.email,
        name: decoded.name,
        profile_pic: decoded.picture,
      });

      if (response.data.success) {
        dispatch(setUser({
          _id: response.data.data._id,
          name: response.data.data.name,
          email: response.data.data.email,
          profile_pic: response.data.data.profile_pic,
        }));

        dispatch(setToken(credentialResponse.credential));
        toast.success('Registro exitoso con Google');
        navigate('/app/home');
      } else {
        toast.error('Error al registrarse con Google');
      }
    } catch (error) {
      toast.error('Error al procesar la solicitud');
    }
  };

  const handleUploadPhoto = async (e) => {
    const file = e.target.files[0];
    setIsUploading(true); // Inicia el estado de carga de la foto

    try {
      const uploadPhoto = await uploadFile(file);
      setUploadPhoto(file);

      setData((prev) => ({
        ...prev,
        profile_pic: uploadPhoto?.url
      }));
    } catch (error) {
      toast.error("Error al subir la foto. Intenta nuevamente.");
    } finally {
      setIsUploading(false); // Finaliza el estado de carga de la foto
    }
  };

  const handleClearUploadPhoto = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setUploadPhoto(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!/\S+@\S+\.\S+/.test(data.email)) {
      return toast.error("Por favor ingresa un correo válido.");
    }
    if (data.password.length < 6) {
      return toast.error("La contraseña debe tener al menos 6 caracteres.");
    }

    setIsSubmitting(true); // Inicia el estado de carga de formulario
    const URL = `${process.env.REACT_APP_BACKEND_URL}/api/register`;

    try {
      const response = await axios.post(URL, data);
      toast.success(response.data.message);

      if (response.data.success) {
        setData({
          name: "",
          email: "",
          password: "",
          profile_pic: ""
        });

        navigate('/app/email');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error al registrar.");
    } finally {
      setIsSubmitting(false); // Finaliza el estado de carga de formulario
    }
  };

  return (
    <>
      <HeaderInicio />

      <div className='flex items-center justify-center min-h-screen bg-gray-100'>
        <div className='bg-white w-full max-w-md rounded-lg overflow-hidden p-6 shadow-lg'>
          <div className='w-fit mx-auto mb-4'>
            <PiUserCircle size={80} className='text-gray-600' />
          </div>

          <h3 className='text-center text-xl font-semibold text-gray-700 mb-4'>
            ¡Bienvenido! Crea tu cuenta
          </h3>

          {/* Botón de Google Login */}
          <div className='flex justify-center mb-6'>
            <div style={{ transform: 'scale(1.5)', padding: '10px' }}>
              <GoogleLogin
                onSuccess={handleGoogleSuccess}
                onError={() => {
                  console.log('Login Failed');
                }}
              />
            </div>
          </div>

          {/* Separador */}
          <div className='text-center text-gray-600 my-4'>
            <span>o</span>
          </div>

          <form className='grid gap-4' onSubmit={handleSubmit}>
            {/* Nombre */}
            <div className='flex flex-col gap-1'>
              <label htmlFor='name' className='text-gray-700'>Nombre:</label>
              <input
                type='text'
                id='name'
                name='name'
                placeholder='Ingresa tu nombre'
                className='bg-slate-100 px-3 py-2 focus:outline-primary border border-gray-300 rounded-md'
                value={data.name}
                onChange={handleOnChange}
                required
              />
            </div>

            {/* Email */}
            <div className='flex flex-col gap-1'>
              <label htmlFor='email' className='text-gray-700'>Correo:</label>
              <input
                type='email'
                id='email'
                name='email'
                placeholder='Ingresa tu correo electrónico'
                className='bg-slate-100 px-3 py-2 focus:outline-primary border border-gray-300 rounded-md'
                value={data.email}
                onChange={handleOnChange}
                required
              />
            </div>

            {/* Contraseña */}
            <div className='flex flex-col gap-1'>
              <label htmlFor='password' className='text-gray-700'>Contraseña:</label>
              <input
                type='password'
                id='password'
                name='password'
                placeholder='Ingresa tu contraseña'
                className='bg-slate-100 px-3 py-2 focus:outline-primary border border-gray-300 rounded-md'
                value={data.password}
                onChange={handleOnChange}
                required
              />
            </div>

            {/* Foto de Perfil */}
            <div className='flex flex-col gap-1'>
              <label htmlFor='profile_pic' className='text-gray-700'>Foto de Perfil:</label>
              <div
                className='h-14 bg-slate-200 flex justify-center items-center border rounded hover:border-primary cursor-pointer'
                onClick={() => inputFileRef.current.click()}
              >
                {isUploading ? (
                  <span>Cargando foto...</span> // Indicador de carga
                ) : (
                  <p className='text-sm max-w-[300px] text-ellipsis line-clamp-1'>
                    {uploadPhoto?.name ? uploadPhoto?.name : "Sube una foto de perfil"}
                  </p>
                )}
                {uploadPhoto?.name && (
                  <button className='text-lg ml-2 hover:text-red-600' onClick={handleClearUploadPhoto}>
                    <IoClose />
                  </button>
                )}
              </div>

              {/* Input de archivo oculto con referencia */}
              <input
                type='file'
                id='profile_pic'
                name='profile_pic'
                className='hidden'
                ref={inputFileRef}
                onChange={handleUploadPhoto}
              />
            </div>

            {/* Botón de Registro */}
            <button
              type="submit"
              className='bg-green-500 text-lg px-4 py-2 hover:bg-green-700 rounded font-bold text-white'
              disabled={isSubmitting} // Deshabilita el botón mientras se envía el formulario
            >
              {isSubmitting ? "Registrando..." : "Registrarse"} {/* Texto del botón según el estado */}
            </button>
          </form>

          <p className='my-4 text-center text-gray-600'>
            ¿Ya tienes una cuenta? <Link to={"/app/email"} className='hover:text-blue-500 font-semibold'>Iniciar sesión</Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
