// src/pages/PsychologistDashboard.js
import React, { useState } from 'react';
import PsychologistSidebar from '../components/PsychologistSidebar';
import logo from '../assets/logotexto.png';

const PsychologistDashboard = () => {
  const [showUpdates, setShowUpdates] = useState(true);

  return (
    <div className="flex min-h-screen">
      <PsychologistSidebar /> {/* Sidebar específico para psicólogos */}
      <div className="flex-1 p-8 pt-16">
        <div className="text-center mb-8">
          <img src={logo} alt="logo" className="mx-auto w-48" />
          <h1 className="text-2xl font-bold mt-4">Bienvenido al Panel de Psicólogo</h1>
          <p className="mt-4 text-gray-600">Aquí puedes gestionar tus sesiones, mensajes y perfil.</p>
        </div>
        
        {showUpdates && (
          <div className="mt-6 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 rounded-lg">
            <h3 className="text-xl font-semibold">¡Novedades próximamente!</h3>
            <p>Pronto se podrá realizar videollamadas. Estamos trabajando en ello.</p>
            <button
              onClick={() => setShowUpdates(false)}
              className="text-sm text-blue-500 hover:underline mt-2 block"
            >
              Ocultar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PsychologistDashboard;
