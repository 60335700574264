import React, { useEffect } from 'react';  // Asegúrate de importar useEffect
import img1 from "../../assets/wallpaper.webp";
// Importa FontAwesomeIcon desde react-fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Importa los íconos desde free-solid-svg-icons
import { faHeartPulse, faBrain, faRunning, faHandsHelping } from '@fortawesome/free-solid-svg-icons';
import Footer from '../Extras/Footer';
import Header from '../Extras/HeaderInicio';

// Componente InfoSection dentro del mismo archivo Home.js
const InfoSection = ({ title, content, imgSrc, reverse }) => {
    return (
      <div data-aos="fade-up" className={`flex flex-col md:flex-row ${reverse ? "md:flex-row-reverse" : ""} items-center my-8`}>
        <div className="md:w-1/2 px-4">
          <h2 className="text-3xl font-bold mb-4">{title}</h2>
          <p className="text-lg text-gray-700 text-justify">{content}</p>
        </div>
        <div className="md:w-1/2 p-4">
          <img src={imgSrc} alt={title} className="rounded-lg shadow-lg" />
        </div>
      </div>
    );
};

const Home = () => {
    useEffect(() => {
        // Forzar el desplazamiento al inicio cuando se carga el componente
        window.scrollTo(0, 0);
      }, []);
    return (
    <div className="min-h-screen flex flex-col justify-between">
      <Header />
      {/* Contenido principal */}
      <div className="container mx-auto p-6">
        <h1 className="text-5xl font-bold text-center my-12">Estrés</h1>
        
        {/* Sección 1: ¿Qué es el estrés? */}
        <InfoSection 
          title={<><FontAwesomeIcon icon={faHeartPulse} className="inline-block text-green-500 mr-2"/> ¿Qué es el estrés?</>} 
          content="El estrés es una reacción natural del cuerpo ante situaciones que percibe como amenazantes o desafiantes. Puede ser provocado por diversas circunstancias, como problemas en el trabajo, estudios, relaciones o situaciones inesperadas. Aunque el estrés es una respuesta normal y a veces útil, ya que nos prepara para enfrentar desafíos, cuando se vuelve crónico o excesivo puede afectar negativamente tanto la mente como el cuerpo." 
          imgSrc={img1}  
          reverse={false}
          data-aos="fade-up" 
        />
        
        {/* Sección 2: ¿Cómo se Siente el estrés? */}
        <InfoSection 
          title={<><FontAwesomeIcon icon={faBrain} className="inline-block text-green-500 mr-2"/> ¿Cómo se Siente el estrés?</>} 
          content="Cuando experimentamos estrés, nuestro cuerpo entra en lo que se conoce como modo de lucha o huida. Esto puede provocar una serie de síntomas físicos, como un aumento del ritmo cardíaco, respiración acelerada, tensión muscular y sudoración. Mentalmente, el estrés puede hacer que te sientas abrumado, ansioso o irritado, y puede dificultar la concentración o la toma de decisiones." 
          imgSrc={img1}  
          reverse={true}
          data-aos="fade-up"
        />
  
        {/* Sección 3: ¿Por Qué Siento Estrés? */}
        <InfoSection 
          title={<><FontAwesomeIcon icon={faRunning} className="inline-block text-green-500 mr-2"/> ¿Por Qué Siento Estrés?</>} 
          content="El estrés puede ser causado por muchas cosas, desde situaciones cotidianas como una carga de trabajo abrumadora o problemas familiares, hasta eventos importantes como mudarse, cambiar de empleo o enfrentar dificultades económicas." 
          imgSrc={img1}  
          reverse={false}
          data-aos="fade-up"
        />
        
        {/* Sección 4: ¿Cómo Puedo Manejar el Estrés? */}
        <InfoSection 
          title={<><FontAwesomeIcon icon={faHandsHelping} className="inline-block text-green-500 mr-2"/> ¿Cómo Puedo Manejar el Estrés?</>} 
          content={
            <>
              <p>Manejar el estrés implica adoptar una serie de hábitos saludables. Practicar técnicas de relajación, como la respiración profunda, el yoga o la meditación, puede ayudar a calmar el cuerpo y la mente. Mantener una rutina de ejercicio regular también es eficaz.</p>
              
              <p className="mt-4">
                Si estás buscando más consejos sobre cómo gestionar el estrés, puedes darle clic <span className="text-green-500 underline ml-1">
                aquí 
                </span> para descubrir estrategias adicionales que pueden ayudarte a reducir la tensión y mejorar tu bienestar.
              </p>
            </>
          } 
          imgSrc={img1}  
          reverse={true}
          data-aos="fade-up"
        />
      </div>
      
      {/* Footer */}
      <Footer /> {/* Aquí agregas el footer */}
    </div>
  );
};
  
export default Home;
