import React from 'react';
import Footer from '../Extras/Footer';
import Header from '../Extras/HeaderInicio';

const TeamsProfilePage = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-between">
      <Header />
      {/* Contenido principal */}
      <div className="flex-grow flex flex-col items-center py-8 px-4">
        <h1 className="text-4xl font-bold text-gray-900 mb-8">
          Conoce a Nuestros Creadores
        </h1>

        {/* Contenedor para los perfiles de las dos personas */}
        <div className="flex flex-col md:flex-row md:space-x-8 items-stretch w-full md:w-3/4">
          {/* Perfil Persona 1 */}
          <div className="bg-white shadow-lg rounded-lg p-6 w-full md:w-1/2 mb-8 md:mb-0 flex flex-col h-full">
            <h2 className="text-2xl font-bold text-gray-900 text-center">
              Diego Alonso Diestra Vega
            </h2>
            <p className="mt-2 text-gray-600 text-center">
              Desarrollador Full Stack & Fundador
            </p>

            <div className="mt-4 flex flex-col items-center flex-grow">
              <img
                src="https://via.placeholder.com/150"
                alt="Persona 1"
                className="rounded-full shadow-md w-32 h-32 object-cover"
              />
              <div className="mt-4 max-w-md text-justify">
                <p className="text-gray-600">
                Hola soy Diego, un apasionado desarrollador full stack con una sólida experiencia en la creación de soluciones tecnológicas innovadoras. He tenido el privilegio de liderar el desarrollo integral de TuEspacio, desde el diseño de la interfaz de usuario hasta la implementación del back-end y la arquitectura del sistema. Mi compromiso con la salud mental me motiva a crear una plataforma accesible y eficiente, diseñada para ayudar a quienes más lo necesitan.
                </p>
              </div>

              <div className="mt-4 max-w-md text-justify">
                <h3 className="text-xl font-bold">Mis Contribuciones:</h3>
                <p className="text-gray-600">
                  He estado al frente de:
                </p>
                <ul className="list-disc list-inside text-gray-600 mt-2">
                  <li>El diseño de la interfaz de usuario.</li>
                  <li>La implementación del servidor.</li>
                  <li>La gestión de la base de datos.</li>
                  <li>La integración del chatbot.</li>
                </ul>
                <p className="text-gray-600 mt-4">
                  Gracias a mi enfoque en la optimización y el rendimiento de cada componente, he logrado desarrollar un sistema robusto y ágil que garantiza una experiencia de usuario fluida y satisfactoria.
                </p>
              </div>


              <div className="mt-4 max-w-md text-justify">
                <h3 className="text-xl font-bold">Mi Motivación:</h3>
                <p className="text-gray-600">
                Mi misión es utilizar la tecnología para hacer que el apoyo psicológico sea accesible para todos. Aspiro a crear una plataforma que no solo sea funcional y fácil de navegar, sino que también ofrezca una experiencia de apoyo emocional cercana y efectiva para quienes buscan mejorar su bienestar mental. Cada línea de código que escribo está orientada a empoderar a las personas en su camino hacia la salud mental.
                </p>
              </div>
            </div>
          </div>

          {/* Perfil Persona 2 */}
          <div className="bg-white shadow-lg rounded-lg p-6 w-full md:w-1/2 mb-8 md:mb-0 flex flex-col h-full">
            <h2 className="text-2xl font-bold text-gray-900 text-center">
              Carlos Alexis Simón Quispe
            </h2>
            <p className="mt-2 text-gray-600 text-center">
              Coordinador de Proyecto & Co-fundador
            </p>

            <div className="mt-4 flex flex-col items-center flex-grow">
              <img
                src="https://via.placeholder.com/150"
                alt="Persona 2"
                className="rounded-full shadow-md w-32 h-32 object-cover"
              />
              <div className="mt-4 max-w-md text-justify">
                <p className="text-gray-600">
                  Hola soy Carlos, ingeniero de sistemas con una amplia experiencia en la gestión de proyectos tecnológicos. Como Coordinador de Proyecto en TuEspacio, tengo la responsabilidad de organizar las reuniones del equipo, evaluar las necesidades de los usuarios y supervisar los informes del proyecto. Mi enfoque se centra en asegurar que nuestra plataforma cumpla con los más altos estándares de accesibilidad y funcionalidad.
                </p>
              </div>

              <div className="mt-4 max-w-md text-justify">
                <h3 className="text-xl font-bold">Mis Contribuciones:</h3>
                <p className="text-gray-600">
                He liderado las reuniones estratégicas del equipo y he evaluado las necesidades de nuestra población objetivo, documentando cada fase del desarrollo. Esto me permite asegurar que el proyecto se mantenga alineado con nuestros objetivos de accesibilidad y calidad.
                </p>
              </div>

              <div className="mt-4 max-w-md text-justify">
                <h3 className="text-xl font-bold">Motivación:</h3>
                <p className="text-gray-600">
                Creo firmemente que todos merecen acceso a un tratamiento psicológico de calidad, sin importar las barreras geográficas o económicas. Mi objetivo es utilizar la tecnología para crear una plataforma que acerque ese apoyo a quienes más lo necesitan, de manera accesible, segura y eficaz. Cada día, trabajo con la convicción de que podemos hacer una diferencia en la vida de las personas que buscan mejorar su bienestar mental.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default TeamsProfilePage;
