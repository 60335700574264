import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Infoapoyopracticantes.module.css';

const ApoyoConPracticantes = () => {
  return (
    <div className="container">
      <header>
        <h1>Apoyo con Practicantes</h1>
      </header>

      {/* Sección de Introducción */}
      <section className="intro">
        <p>
          En <strong>TuEspacio</strong>, te ofrecemos un tratamiento psicológico accesible y de alta calidad con la ayuda de nuestros practicantes. Estos estudiantes avanzados de psicología han sido cuidadosamente seleccionados y están supervisados por profesionales licenciados, garantizando un proceso terapéutico seguro, eficaz y personalizado.
        </p>
      </section>

      {/* Sección de Tratamiento Psicológico Gratuito */}
      <section className="section">
        <h2>Tratamiento Psicológico Gratuito</h2>
        <p>
          Sabemos que el costo puede ser una barrera para muchas personas. Por eso, en <strong>TuEspacio</strong> ofrecemos tratamiento psicológico gratuito a través de nuestros practicantes, brindándote la posibilidad de acceder al apoyo emocional que necesitas sin preocuparte por los gastos.
        </p>
      </section>

      {/* Sección de Atención Personalizada */}
      <section className="section">
        <h2>Atención Personalizada para Cada Paciente</h2>
        <p>
          Cada paciente tiene una historia única y necesidades particulares. Nuestros practicantes trabajan contigo para diseñar un plan de tratamiento personalizado, ajustado a tus objetivos y circunstancias. El proceso terapéutico se adapta a ti, para que recibas el apoyo más adecuado a tu situación.
        </p>
      </section>

      {/* Sección de Supervisión Constante */}
      <section className="section">
        <h2>Supervisión Constante de Profesionales</h2>
        <p>
          Cada sesión y plan de tratamiento está supervisado por psicólogos licenciados con amplia experiencia. Esto asegura que las decisiones y recomendaciones de los practicantes estén siempre guiadas y respaldadas por profesionales, brindándote confianza y seguridad en cada paso del camino.
        </p>
      </section>

      {/* Sección de Sesiones Virtuales */}
      <section className="section">
        <h2>Sesiones Virtuales, Cómodas y Seguras</h2>
        <p>
          Realizar tus sesiones es más fácil que nunca. Desde la comodidad de tu hogar, puedes conectarte con tu practicante a través de videollamadas seguras. De esta manera, te aseguramos un espacio confidencial y accesible sin la necesidad de desplazarte.
        </p>
      </section>

      {/* Sección de Confidencialidad */}
      <section className="section">
        <h2>Confidencialidad y Respeto a tu Privacidad</h2>
        <p>
          Tu privacidad es nuestra máxima prioridad. Toda la información que compartas será tratada con la más estricta confidencialidad. Nos comprometemos a proteger tus datos y garantizar que te sientas seguro y respetado en todo momento.
        </p>
      </section>
    </div>
  );
};

export default ApoyoConPracticantes;
