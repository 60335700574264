// src/pages/PsychologistProfile.js
import React from 'react';

const PsychologistProfile = () => {
  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Perfil del Psicólogo</h1>
      <p>En esta sección puedes actualizar tu información personal y tus credenciales profesionales.</p>
      {/* Implementar formulario de perfil aquí */}
    </div>
  );
};

export default PsychologistProfile;
