import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./HeaderInicio.css";
import logo from "../../assets/logotexto.png";
import { HiMenu, HiX } from "react-icons/hi"; // Íconos para el menú hamburguesa
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai"; // Íconos para las flechas

const HeaderInicio = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(null);

  const toggleSubMenu = (menu) => {
    setSubMenuOpen(subMenuOpen === menu ? null : menu);
  };

  return (
    <header className="header-inicio">
      <div className="header-container">
        {/* Logo */}
        <Link to="/" onClick={() => setMenuOpen(false)}>
          <img src={logo} alt="Logo" className="header-logo" />
        </Link>
        {/* Menú en pantallas grandes */}
        <nav className="nav-links desktop-menu">
          <Link to="/" className="nav-item">Inicio</Link>

          <div 
            className="nav-item-dropdown"
            onMouseEnter={() => setSubMenuOpen('atenciones')}
            onMouseLeave={() => setSubMenuOpen(null)}
          >
            <button className="nav-item">
              Atenciones
            </button>
            {subMenuOpen === 'atenciones' && (
              <div className="dropdown-menu">
                <Link to="/infoansiedad" className="dropdown-item">Ansiedad</Link>
                <Link to="/infoestres" className="dropdown-item">Estrés</Link>
              </div>
            )}
          </div>

          <div 
            className="nav-item-dropdown"
            onMouseEnter={() => setSubMenuOpen('recursos')}
            onMouseLeave={() => setSubMenuOpen(null)}
          >
            <button className="nav-item">
              Recursos
            </button>
            {subMenuOpen === 'recursos' && (
              <div className="dropdown-menu">
                <Link to="/gestionarestres" className="dropdown-item">Gestionar el estrés</Link>
              </div>
            )}
          </div>

          <div 
            className="nav-item-dropdown"
            onMouseEnter={() => setSubMenuOpen('apoyo')}
            onMouseLeave={() => setSubMenuOpen(null)}
          >
            <button className="nav-item">
              Apoyo
            </button>
            {subMenuOpen === 'apoyo' && (
              <div className="dropdown-menu">
                <Link to="/#" className="dropdown-item">Apoyo con Practicantes</Link>
                <Link to="/#" className="dropdown-item">Apoyo con Profesionales</Link>
                <Link to="/#" className="dropdown-item">Chatbot</Link>
                <Link to="/#" className="dropdown-item">¿Por qué nosotros?</Link>
              </div>
            )}
          </div>

          <Link to="/app/email" className="nav-item signup-button bg">
            Iniciar Sesión
          </Link>
        </nav>
      </div>


        {/* Menú móvil */}
        <div className="mobile-menu">
          <div className="hamburger-btn" onClick={() => setMenuOpen(!menuOpen)}>
            {menuOpen ? <HiX size={30} /> : <HiMenu size={30} />}
          </div>

          {menuOpen && (
            <nav className="nav-links-mobile">
              <Link to="/" className="nav-item-mobile inicio" onClick={() => setMenuOpen(false)}>
                <span>Inicio</span>
              </Link>

              <div className="nav-item-dropdown-mobile atenciones">
                <button className="nav-item-mobile atenciones" onClick={() => toggleSubMenu('atenciones')}>
                  <span>Atenciones</span>
                  <AiOutlineDown className="icon" />
                </button>
                {subMenuOpen === 'atenciones' && (
                  <div className="dropdown-menu-mobile">
                    <Link to="/infoansiedad" className="dropdown-item-mobile">Ansiedad</Link>
                    <Link to="/infoestres" className="dropdown-item-mobile">Estrés</Link>
                  </div>
                )}
              </div>

              <div className="nav-item-dropdown-mobile recursos">
                <button className="nav-item-mobile recursos" onClick={() => toggleSubMenu('recursos')}>
                  <span>Recursos</span>
                  <AiOutlineDown className="icon" />
                </button>
                {subMenuOpen === 'recursos' && (
                  <div className="dropdown-menu-mobile">
                    <Link to="/gestionarestres" className="dropdown-item-mobile">Gestionar el estrés</Link>
                  </div>
                )}
              </div>

              <div className="nav-item-dropdown-mobile apoyo">
                <button className="nav-item-mobile apoyo" onClick={() => toggleSubMenu('apoyo')}>
                  <span>Apoyo</span>
                  <AiOutlineDown className="icon" />
                </button>
                {subMenuOpen === 'apoyo' && (
                  <div className="dropdown-menu-mobile">
                    <Link to="/#" className="dropdown-item-mobile">Apoyo con Practicantes</Link>
                    <Link to="/#" className="dropdown-item-mobile">Apoyo con Profesionales</Link>
                    <Link to="/#" className="dropdown-item-mobile">Chatbot</Link>
                    <Link to="/#" className="dropdown-item-mobile">¿Por qué nosotros?</Link>
                  </div>
                )}
              </div>

              <Link to="/app/email" className="background-color:red nav-item-mobile signup-button-mobile" onClick={() => setMenuOpen(false)}>
                Iniciar Sesión
              </Link>
            </nav>
          )}
        </div>      
    </header>
  );
};

export default HeaderInicio;
