import React, { useEffect } from 'react';  // Asegúrate de importar useEffect
import img1 from "../../assets/wallpaper.webp";
import Header from '../Extras/HeaderInicio';

// Importación de FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faHandsHelping, faShieldAlt, faLightbulb, faUsers, faLock, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

// Importar el Footer
import Footer from '../Extras/Footer';  // Ajusta la ruta según la ubicación de tu Footer

const LandingPage = () => {
    useEffect(() => {
        // Forzar el desplazamiento al inicio cuando se carga el componente
        window.scrollTo(0, 0);
      }, []);
      
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-between">
      <Header />
      <div className="container mx-auto px-6 mb-16"> 

        {/* Sección de texto principal */}
        <div className="flex flex-col md:flex-row items-center mt-16">
        <div className="w-full md:w-1/2">
          <h1 className="text-5xl font-bold text-gray-900 leading-tight">
            Bienvenido a TuEspacio,
          </h1>
          <p className="mt-4 text-gray-600 text-lg text-justify">
            Un sitio virtual creado para hacer más accesible y efectivo el tratamiento psicológico. Nos preocupamos por tu bienestar y estamos aquí para acompañarte en cada etapa de tu camino hacia una mejor salud mental.
          </p>
          <p className="mt-4 text-gray-600 text-lg text-justfy">
            En TuEspacio encontrarás:
          </p>
          <ul className="list-disc list-inside mt-4 text-gray-600 text-lg">
            <li className="mb-2"><strong>Entorno seguro y amigable:</strong> Diseñado para que te sientas cómodo y en confianza.</li>
            <li className="mb-2"><strong>Evaluaciones personalizadas:</strong> Herramientas y test para ayudarte a conocer mejor tu estado emocional.</li>
            <li className="mb-2"><strong>Conexión con profesionales de la salud mental:</strong> Psicólogos calificados listos para apoyarte.</li>
            <li className="mb-2"><strong>Acompañamiento continuo:</strong> Siempre estaremos a tu lado, brindando el apoyo que necesitas.</li>
          </ul>
          <p className="mt-4 text-gray-600 text-lg text-justify">
            Tu bienestar es nuestra prioridad, y estamos aquí para ayudarte a alcanzar el equilibrio emocional que mereces.
          </p>
        </div>


          {/* Sección de imagen con cuadrícula de imágenes */}
          <div className="w-full md:w-1/2 mt-8 md:mt-0 flex justify-center items-center">
            <div className="grid grid-cols-3 gap-4">
              <img src={img1} alt="img1" className="rounded-lg shadow-lg object-cover h-[250px] w-3/4 translate-y-6" />
              <img src={img1} alt="img1" className="rounded-lg shadow-lg object-cover h-[250px] w-3/4 -translate-y-4" />
              <img src={img1} alt="img1" className="rounded-lg shadow-lg object-cover h-[250px] w-3/4 translate-y-6" />
            </div>
          </div>
        </div>

        {/* Sección de misión y bloque de texto de porcentaje */}
        <div className="flex flex-col md:flex-row items-center mt-16">
            <div className="w-full md:w-1/2">
              <h1 className="text-5xl font-bold text-gray-900 leading-tight">
                Nuestra misión
              </h1>
              <p className="mt-4 text-gray-600 text-lg text-justify">
                Nuestra misión es mejorar la calidad y accesibilidad del tratamiento psicológico. Queremos ofrecerte un espacio donde te sientas seguro y comprendido, brindándote las herramientas y el apoyo necesarios para tu bienestar emocional. Nos comprometemos a:
              </p>
              <ul className="list-disc list-inside mt-4 text-gray-600 text-lg">
                <li className="mb-2">Ofrecer un entorno empático y seguro.</li>
                <li className="mb-2">Facilitar la conexión con profesionales de la salud mental.</li>
                <li className="mb-2">Promover la salud mental como un derecho accesible para todos.</li>
                <li className="mb-2">Eliminar las barreras que dificultan el acceso a atención psicológica de calidad.</li>
              </ul>
              <p className="mt-4 text-gray-600 text-lg text-justify">
                Porque creemos que tu bienestar mental es fundamental y debe estar al alcance de cada persona.
              </p>
            </div>


          {/* Bloque de texto con porcentaje */}
          <div className="w-full md:w-1/2 flex flex-col items-center justify-center mt-8 md:mt-0">
            <h1 className="text-5xl font-bold text-green-500">+30%</h1>
            <p className="text-xl text-black-500">Sufre de estrés en Lima</p>
            <h1 className="text-5xl font-bold text-green-500 mt-8">182,399</h1>
            <p className="text-xl text-black-500">Casos de personas con ansiedad</p>
            <h1 className="text-5xl font-bold text-green-500 mt-8">80%</h1>
            <p className="text-xl text-black-500">No recibe atención</p>
          </div>
        </div>

        {/* Nueva sección con imagen */}
        <div className="w-full mt-16">
          <img src={img1} alt="img1" className="w-full h-64 object-cover rounded-lg" />
        </div>

        {/* Sección de Valores */}
        <div className="w-full mt-16">
          <h1 className="text-4xl font-bold text-gray-900 leading-tight">
            Nuestros Valores
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
            {/* Bloques de valores con íconos */}
            <div className="w-full md:w-4/5 mx-auto text-center">
              <FontAwesomeIcon icon={faHeart} size="3x" className="text-green-500 mb-4" /> {/* Icono */}
              <h3 className="text-2xl font-bold text-gray-900">Empatía</h3>
              <p className="mt-4 text-gray-600">Nos ponemos en el lugar de cada persona, comprendiendo sus necesidades y brindando un apoyo cercano y humano.</p>
            </div>
            <div className="w-full md:w-4/5 mx-auto text-center">
              <FontAwesomeIcon icon={faHandsHelping} size="3x" className="text-green-500 mb-4" /> {/* Icono */}
              <h3 className="text-2xl font-bold text-gray-900">Accesibilidad</h3>
              <p className="mt-4 text-gray-600">Trabajamos para que el tratamiento psicológico sea fácil de alcanzar para todos, eliminando barreras económicas y sociales.</p>
            </div>
            <div className="w-full md:w-4/5 mx-auto text-center">
              <FontAwesomeIcon icon={faCheckCircle} size="3x" className="text-green-500 mb-4" /> {/* Icono */}
              <h3 className="text-2xl font-bold text-gray-900">Calidad</h3>
              <p className="mt-4 text-gray-600">Nos esforzamos por ofrecer servicios de alta calidad, conectando a las personas con profesionales capacitados y recursos confiables.</p>
            </div>
            <div className="w-full md:w-4/5 mx-auto text-center">
              <FontAwesomeIcon icon={faLightbulb} size="3x" className="text-green-500 mb-4" /> {/* Icono */}
              <h3 className="text-2xl font-bold text-gray-900">Innovación</h3>
              <p className="mt-4 text-gray-600">Utilizamos la página web para mejorar el acceso y la efectividad de los tratamientos psicológicos, asegurándonos de estar siempre a la vanguardia.</p>
            </div>
            <div className="w-full md:w-4/5 mx-auto text-center">
              <FontAwesomeIcon icon={faUsers} size="3x" className="text-green-500 mb-4" /> {/* Icono */}
              <h3 className="text-2xl font-bold text-gray-900">Inclusión</h3>
              <p className="mt-4 text-gray-600">Valoramos la diversidad y creemos que todas las personas, sin importar su situación, merecen recibir atención y apoyo.</p>
            </div>
            <div className="w-full md:w-4/5 mx-auto text-center">
              <FontAwesomeIcon icon={faLock} size="3x" className="text-green-500 mb-4" /> {/* Icono */}
              <h3 className="text-2xl font-bold text-gray-900">Confidencialidad</h3>
              <p className="mt-4 text-gray-600">Protegemos la privacidad de nuestros usuarios, garantizando que cada interacción se realice con la máxima discreción y seguridad.</p>
            </div>
            <div className="w-full md:w-4/5 mx-auto text-center">
              <FontAwesomeIcon icon={faShieldAlt} size="3x" className="text-green-500 mb-4" /> {/* Icono */}
              <h3 className="text-2xl font-bold text-gray-900">Compromiso</h3>
              <p className="mt-4 text-gray-600">Nos dedicamos a acompañar a nuestros usuarios en su camino hacia el bienestar mental, ofreciéndoles apoyo continuo y soluciones adaptadas a sus necesidades.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Footer añadido al final */}
      <Footer />
    </div>
  );
};

export default LandingPage;
