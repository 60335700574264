// src/router.js
import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import RegisterPage from '../pages/RegisterPage';
import CheckEmailPage from '../pages/CheckEmailPage';
import CheckPasswordPage from '../pages/CheckPasswordPage';
import Home from '../pages/Home';
import MessagePage from '../components/MessagePage';
import AuthLayouts from '../layout';
import Forgotpassword from '../pages/Forgotpassword';
import ProtectedRoute from '../components/ProtectedRoute';
import Inicio from '../pages/Inicio';
import Privacidad from '../components/Legal/privacidad';
import Terminos from '../components/Legal/terminos';
import Ansiedad from '../components/Atencion/infoansiedad';
import Estres from '../components/Atencion/infoestres';
import Gestionarestres from '../components/Recursos/gestionarestres';
import Nosotros from '../components/Compañia/nosotros';
import Creadores from '../components/Compañia/creadores';
import LegalCitas from '../components/Legal/LegalCitas';
import Infoapoyopracticantes from '../components/Apoyo/Infoapoyopracticantes';
import AdminDashboard from '../admin/AdminDashboard';
import LoginAdmin from '../admin/LoginAdmin';
import PsychologistLogin from '../pages/PsychologistLogin';
import PsychologistHome from '../pages/PsychologistHome';
import PsychologistDashboard from '../pages/PsychologistDashboard';
import PsychologistProfile from '../components/Psychologist/PsychologistProfile';
import PsychologistMessages from '../components/Psychologist/PsychologistMessages';

const router = createBrowserRouter([
    {
        path: "/",
        element: <AuthLayouts><Inicio /></AuthLayouts>, 
    },
    {
        path: "/app",
        element: <App />, 
        children: [
            {
                path: "register",
                element: <AuthLayouts><RegisterPage /></AuthLayouts>
            },
            {
                path: 'email',
                element: <AuthLayouts><CheckEmailPage /></AuthLayouts>
            },
            {
                path: 'password',
                element: <AuthLayouts><CheckPasswordPage /></AuthLayouts>
            },
            {
                path: 'forgot-password',
                element: <AuthLayouts><Forgotpassword /></AuthLayouts>
            },
            {
                path: "home",
                element: <ProtectedRoute role="user"><Home /></ProtectedRoute>,
                children: [
                    {
                        path: ':userId',
                        element: <MessagePage />
                    }
                ]
            }
        ]
    },
    {
        path: "/admin",
        element: <LoginAdmin /> // Login exclusivo para administrador
    },
    {
        path: "/dashboard",
        element: (
          <ProtectedRoute role="admin">
            <AdminDashboard />
          </ProtectedRoute>
        ) // Dashboard protegido para el administrador
    },
    {
        path: "/psychologist",
        element: (
          <ProtectedRoute role="psychologist">
            <PsychologistHome />
          </ProtectedRoute>
        ),
        children: [
            {
                path: "dashboard",
                element: <PsychologistDashboard />,
            },
            {
                path: "profile",
                element: <PsychologistProfile />,
            },
            {
                path: "messages",
                element: <PsychologistMessages />,
            },
        ],
    },
    {
        path: "/PsychologistLogin",
        element: <AuthLayouts><PsychologistLogin /></AuthLayouts> // Login para psicólogos
    },
    // Otras rutas informativas y de recursos
    {
        path: "/nosotros",
        element: <AuthLayouts><Nosotros /></AuthLayouts> 
    },
    {
        path: "/privacidad",  
        element: <AuthLayouts><Privacidad /></AuthLayouts>
    },
    {
        path: "/terminos",
        element: <AuthLayouts><Terminos /></AuthLayouts>
    },
    {
        path: "/LegalCitas",
        element: <AuthLayouts><LegalCitas /></AuthLayouts>
    },
    {
        path: "/infoansiedad",
        element: <AuthLayouts><Ansiedad /></AuthLayouts>
    },
    {
        path: "/infoestres",
        element: <AuthLayouts><Estres /></AuthLayouts>
    },
    {
        path: "/gestionarestres",
        element: <AuthLayouts><Gestionarestres /></AuthLayouts>
    },
    {
        path: "/creadores",
        element: <AuthLayouts><Creadores /></AuthLayouts>
    },
    {
        path: "/Infoapoyopracticantes",
        element: <AuthLayouts><Infoapoyopracticantes /></AuthLayouts>
    }
]);

export default router;
