import React, { useEffect } from 'react';  // Asegúrate de importar useEffect
import img1 from "../../assets/wallpaper.webp";
import { FaBrain, FaHeartbeat, FaQuestionCircle, FaMedkit } from 'react-icons/fa';
import Footer from '../Extras/Footer';
import Header from '../Extras/HeaderInicio';

// Componente InfoSection dentro del mismo archivo Home.js
const InfoSection = ({ title, content, imgSrc, reverse }) => {
  return (
    <div data-aos="fade-up" className={`flex flex-col md:flex-row ${reverse ? "md:flex-row-reverse" : ""} items-center my-8`}>
      <div className="md:w-1/2 px-4">
        <h2 className="text-3xl font-bold mb-4">{title}</h2>
        <p className="text-lg text-gray-700 text-justify">{content}</p>
      </div>
      <div className="md:w-1/2 p-4">
        <img src={imgSrc} alt={title} className="rounded-lg shadow-lg" />
      </div>
    </div>
  );
};



const Home = () => {

    useEffect(() => {
        // Forzar el desplazamiento al inicio cuando se carga el componente
        window.scrollTo(0, 0);
      }, []);
      
  return (
    <div className="min-h-screen flex flex-col justify-between">
      <Header />
      {/* Contenido principal */}
      <div className="container mx-auto p-6">
        <h1 className="text-5xl font-bold text-center my-12">Ansiedad</h1>
        <InfoSection  
        title={<><FaBrain className="inline-block text-green-500 mr-2" />¿Qué es la ansiedad?</>}
        content="La ansiedad es una sensación de preocupación, nerviosismo o malestar que todos experimentamos en algún momento. Es una respuesta natural del cuerpo ante situaciones estresantes o desconocidas. Imagina que estás esperando una respuesta importante o enfrentándote a un examen; es normal sentir un poco de ansiedad en estos casos."
        imgSrc={img1}
        reverse={false}
      />
      
      {/* Sección 2: ¿Cómo se Siente la Ansiedad? */}
      <InfoSection 
        title={<><FaHeartbeat className="inline-block text-green-500 mr-2" />¿Cómo se Siente la Ansiedad?</>}
        content="Cuando experimentas ansiedad, puedes sentir una preocupación constante que no te deja tranquilo. Tu cuerpo puede parecer estar en modo alerta, lo que se manifiesta como una sensación de nerviosismo. Puede ser difícil concentrarte en tareas cotidianas debido a la inquietud mental, y a veces, también puedes experimentar síntomas físicos como palpitaciones, sudoración excesiva o temblores. Estos síntomas físicos acompañan el malestar emocional y pueden hacer que te sientas incómodo."
        imgSrc={img1}
        reverse={true}
      />

      {/* Sección 3: ¿Por Qué Siento Ansiedad? */}
      <InfoSection 
        title={<><FaQuestionCircle className="inline-block text-green-500 mr-2" />¿Por Qué Siento Ansiedad?</>}
        content="La ansiedad puede surgir por diversas razones. El estrés, como los problemas en el trabajo, en la escuela o en las relaciones personales, puede desencadenarla. También es común sentir ansiedad ante cambios importantes en la vida, como mudarse a una nueva ciudad o comenzar un nuevo trabajo. Además, las preocupaciones diarias relacionadas con asuntos financieros, familiares o de salud pueden contribuir a la sensación de ansiedad. Es importante reconocer que estos factores son comunes y pueden afectar a cualquiera."
        imgSrc={img1}
        reverse={false}
      />
      
      {/* Sección 4: ¿Cómo Puedo Manejar la Ansiedad? */}
      <InfoSection 
        title={<><FaMedkit className="inline-block text-green-500 mr-2" />¿Cómo Puedo Manejar la Ansiedad?</>}
        content="Para manejar la ansiedad de manera efectiva, hay varias estrategias que puedes probar. La respiración profunda es una técnica útil: tomar respiraciones largas y lentas puede ayudar a calmar tu cuerpo. El ejercicio regular también es beneficioso, ya que la actividad física ayuda a reducir el estrés acumulado. Hablar con alguien de confianza, ya sea un amigo, familiar o profesional, puede aliviar la carga emocional y proporcionar una perspectiva diferente. Además, es importante dedicar tiempo a actividades que disfrutes y que te ayuden a relajarte. Recuerda que, aunque la ansiedad es una parte normal de la vida, si sientes que está afectando significativamente tu bienestar, buscar ayuda profesional puede ser una opción valiosa. Estamos aquí para apoyarte en cada paso del camino."
        imgSrc={img1}
        reverse={true}
      />
     </div>
      
      {/* Footer */}
      <Footer /> {/* Aquí agregas el footer */}
    </div>
  );
};

export default Home;
